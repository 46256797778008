import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';
import PrivacyPolicy from './privacy_policy';
import Disclaimer from './disclaimer';
import Help from './help';
import Terms from './terms_and_conditions';


const React = require('react')
const ReactDOM = require('react-dom')
const ReactMarkdown = require('react-markdown')

const useStyles = makeStyles(theme => ({
  legal: {
    margin:50,
  }
}));


const input = `# Disclaimer

Created : 2019-12-02

**Warning**

Excessive Hangboard Training is Dangerous

By using HangBird you are acklowledging that you understand the risks and agree that the creators of HangBird are not liable for any injuries that may occur during the course of training.`

export default function Markdown() {
  const classes = useStyles();

  return (
    <Switch>
      <Route path="/disclaimer">
        <ReactMarkdown source={Disclaimer.content} className={classes.legal} />
      </Route>
      <Route path="/privacy_policy">
        <ReactMarkdown source={PrivacyPolicy.content} className={classes.legal} />
      </Route>
      <Route path="/help">
        <ReactMarkdown source={Help.content} className={classes.legal} />
      </Route>

      <Route path="/terms_and_conditions">
        <ReactMarkdown source={Terms.content} className={classes.legal} />
      </Route>

    </Switch>
  );
}
