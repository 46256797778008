exports.content = `# Terms and Conditions

Created : 2019-12-02

## General

By downloading or using the app, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using the app. You’re not allowed to copy, or modify the app, any part of the app, or our trademarks in any way. You’re not allowed to attempt to extract the source code of the app, and you also shouldn’t try to translate the app into other languages, or make derivative versions. The app itself, and all the trade marks, copyright, database rights and other intellectual property rights related to it, still belong to Vohu Systems Corporation.

Vohu Systems Corporation is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without making it very clear to you exactly what you’re paying for.

The HangBird app stores and processes personal data that you have provided to us, in order to provide our Service. It’s your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the HangBird app won’t work properly or at all.

You should be aware that there are certain things that Vohu Systems Corporation will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi, or provided by your mobile network provider, but Vohu Systems Corporation cannot take responsibility for the app not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance left.

If you’re using the app outside of an area with Wi-Fi, you should remember that your terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third party charges. In using the app, you’re accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you’re using the app, please be aware that we assume that you have received permission from the bill payer for using the app.

Along the same lines, Vohu Systems Corporation cannot always take responsibility for the way you use the app i.e. You need to make sure that your device stays charged – if it runs out of battery and you can’t turn it on to avail the Service, Vohu Systems Corporation cannot accept responsibility.

With respect to Vohu Systems Corporation’s responsibility for your use of the app, when you’re using the app, it’s important to bear in mind that although we endeavour to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. Vohu Systems Corporation accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.

At some point, we may wish to update the app. The app is currently available on Android & iOS – the requirements for both systems (and for any additional systems we decide to extend the availability of the app to) may change, and you’ll need to download the updates if you want to keep using the app. Vohu Systems Corporation does not promise that it will always update the app so that it is relevant to you and/or works with the Android & iOS version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.

**Changes to This Terms and Conditions**

We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page. These changes are effective immediately after they are posted on this page.


## Billing Terms

Vohu Systems Corporation offers a subscription-based service to the content within the HangBird App.

All purchases are subject to our Terms of Service.

Subscriptions can be purchased directly from: In-App Purchase

Different subscription plans may be offered depending on Your location or place of purchase.

The subscription fee will apply regardless of how much or how often You access or use Your subscription.

**In-App Purchase**

These include a purchase made via an external App Store, such as the Apple App Store or Google Play Store, or another similar App Store.

An In-App Purchase is purchased from and billed by the respective App Store, not by Us.

You agree that:

An In-App Purchase is subject to the terms, conditions and other licensing arrangements specified by that App Store provider;

In-App Purchases are processed securely via the payment gateway used by the relevant App Store provider; and

You must take up with the App Store provider directly any payment-related issues You experience with an In-App Purchase or return requests.

**Free Trials**

Your HangBird subscription may start with a free trial for a fixed period as specified at the time of sign up.

Free trials are only available to new HangBird users, and are at the sole discretion of HangBird, and if You attempt to sign up for an additional free trial you will be immediately charged with the standard subscription price.

We reserve the right to revoke Your free trial at any time.

Unless You cancel before the end of the trial period, Your free trial will automatically roll over to a paid subscription.

Any unused portion of Your free trial period will be forfeited upon purchase of a subscription.

**Promotions**

We may offer special promotions, trials, discounts, offers or coupons from time to time directly through HangBird or through third parties, subject to and on its terms and conditions separate to those of HangBird. It is Your responsibility to read and understand those terms and conditions.

Any trial offers or subscriptions will automatically rollover to a paid subscription unless cancelled at the end of the promotional period, or unless otherwise stated.

**Cancellation**

You may cancel Your subscription at any time.

Unless You cancel at least 24 hours before the start of Your next billing period, you will be charged for the next billing period.

After cancellation, You will continue to have access to the HangBird App for the remainder of Your billing period.

You can cancel your subscription via the relevant App Store where you made the purchase.

We do not offer refunds or credits for unused subscription periods, accidental purchases, medical conditions, or any similar reason or event, unless required by law to do so.

If you are dissatisfied for any reason, please contact our Support Team at support@hangbird.app.

**Interpretation**

Billing Terms means theses billing terms and conditions outlined herein including any additions or variations of these HangBird Billing Terms made by Us;

Products means any item including, goods, services, digital products, ebooks, downloads, resource material, online content, and other similar content sold by Us to a customer or subscriber.

Terms of Service refers to and includes Our Privacy Policy, Returns Policy and Disclaimers which can be viewed at https://www.hangbird.app, including any additions or variations to these policies and disclaimers made by Us;

We or Our or Us refers to the Vohu Systems Corporation;

You or Your refers to you, the customer or subscriber.


**Contact Us**

If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact us at support@hangbird.app.`
