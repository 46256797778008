import React from 'react';
import './App.css';
import logo from "./images/logo.png"

import androidBadge from './images/google-play-badge.png'
import iosBadge from './images/ios-app-store-badge.png'
import headerImage from './images/header.png'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import 'typeface-roboto';

import routine_builder from './images/recordings/test.gif'

import get_simple from './images/recordings/get_simple.gif'
import get_consistent from './images/recordings/get_consistent.gif'
import get_specific from './images/recordings/get_specific.gif'
import get_scientific from './images/recordings/get_scientific.gif'
import get_connected from './images/recordings/get_connected.gif'


const styles = theme => ({
  appBody:{
    [theme.breakpoints.up('sm')]: { margin:50 },
    [theme.breakpoints.only('xs')]: { margin:5 },
    padding:30,
  },
  appHeader:{
    marginTop:20,
  },
  appFooter:{
    paddingBottom:10,
    justifyContent:'center',
    alignItems:'center',
    display:'flex',
    marginTop:50,
  },
  helperCard:{
    padding:20,
  },
  headerCopy:{
  },
  activitySummary:{
    marginLeft:10,
  },
  subheading:{
    marginLeft:10,
  },
  topSpacer:{
    height:50,
  },
  subheadDivider:{
    marginBottom:10,
  }

})

const muiTheme = createMuiTheme({});

const { breakpoints, typography: { pxToRem } } = muiTheme;


function App(props) {
  return (
    <MuiThemeProvider theme={muiTheme} >
      <CssBaseline />
      <div
        style={{
          backgroundImage: `url(${headerImage})`,
          position: "absolute",
          top: 0,
          zIndex: -5,
          height: "100vh",
          width: "100vw",
          opacity: 0.5,
        }}
      />

      <Paper className={props.classes.appBody}>
        <header className={props.classes.appHeader}>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={3}>
              <Grid container justify="center">
                <Grid item>
                  <img src={logo} alt='bird hanging' width={340} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={9}>
              <div className={props.classes.headerCopy} >
                <Grid item>
                  <Typography className={props.classes.subheading} variant="h4">Develop Superhuman Finger Strength</Typography>
                  <div className={props.classes.activitySummary}>
                    <div><b>72</b> rock climbers</div>
                    <div><b>479</b> workouts completed</div>
                    <div><b>109476</b> seconds total hang time</div>
                  </div>
                  <a href="https://apps.apple.com/us/app/hangbird/id1469391866" style={{boxShadow:'none', display:'inline-block', padding:'10px'}} ><img style={{margin:0, float:'left'}} width='135px' alt='Download on the App Store' src={iosBadge}/></a>
                  <a href='https://play.google.com/store/apps/details?id=com.monster.hngb1rd&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' style={{boxShadow:'none', display:`inline-block`, padding:'10px'}}><img style={{margin:0, float:'left'}} width='135px' alt='Get it on Google Play' src={androidBadge}/></a>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </header>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Paper className={props.classes.helperCard}>
              <Typography variant="h4">Get Simple</Typography>
              <Divider className={props.classes.subheadDivider} />
              <Grid container justify="center"><Grid item><img src={get_simple} alt='adding exercise to routine' /></Grid></Grid>
              <Typography variant="subtitle1">Overcome paralysis by analysis with minimal setup to get you started.</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={props.classes.helperCard}>
              <Typography variant="h4">Get Consistent</Typography>
              <Divider className={props.classes.subheadDivider} />
              <Grid container justify="center"><Grid item><img src={get_consistent} alt='modify preogression before workout' /></Grid></Grid>
              <Typography variant="subtitle1">Create your own routine and save time figuring out what to train next.</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={props.classes.helperCard}>
              <Typography variant="h4">Get Specific</Typography>
              <Divider className={props.classes.subheadDivider} />
              <Grid container justify="center"><Grid item><img src={get_specific} alt='adding max hang with grip selection' /></Grid></Grid>
              <Typography variant="subtitle1">Train max hangs or repeaters to target the appropriate energy system for your project.  Train the appropriate grips to target your project's crux moves.</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={props.classes.helperCard}>
              <Typography variant="h4">Get Scientific</Typography>
              <Divider className={props.classes.subheadDivider} />
              <Grid container justify="center"><Grid item><img src={get_scientific} alt='view training stats' /></Grid></Grid>
              <Typography variant="subtitle1">Visualize your progress on a variety of training metrics to see what works.  Conduct your own periodic assessments to test your absolute limit every once in a while.</Typography>
            </Paper>
          </Grid>
        </Grid>


        <footer className={props.classes.appFooter}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            &#169; 2018-2020 Hangbird

          </div>
        </footer>
    </Paper>

    </MuiThemeProvider>
  );
}

export default withStyles(styles)(App);
