import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "typeface-roboto";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { unregister as unregisterServiceWorker } from './registerServiceWorker'



import Markdown from './markdown/index.js';

ReactDOM.render((
  <BrowserRouter>
    <Switch>
      <Route path="/disclaimer">
        <Markdown />
      </Route>
      <Route path="/privacy_policy">
        <Markdown />
      </Route>
      <Route path="/help">
        <Markdown />
      </Route>
      <Route path="/terms_and_conditions">
        <Markdown />
      </Route>
      <Route path="/">
        <App />
      </Route>
    </Switch>
  </BrowserRouter>
), document.getElementById('root'));
unregisterServiceWorker();
